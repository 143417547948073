































































































































































































































import CenteredColumnLayout from '@/components/CenteredColumnLayout.vue'
import NotAvailableOptionsOverlay from '@/components/NotAvailableOptionsOverlay.vue'
import { InputSetups } from '../../mixins/input-setups'
import TariffsTagsHelper from '../../mixins/TariffsTagsHelper'
import Placeholders from '@/mixins/placeholders/placeholders'
import CreateTriggerModal from '@/components/CreateTriggerModal/CreateTriggerModal.vue'
import TempCurrentModuleMixin from '@/includes/logic/Modules/mixins/TempCurrentModuleMixin'
import { ModuleTypesEnum } from '@/includes/logic/Modules/types/types'
import CurrentModule from '@/components/Modules/components/CurrentModule.vue'

import { MediaEditorMode } from 'piramis-base-components/src/components/MessageEditorWithMedia/types'
import { UseFields } from 'piramis-base-components/src/components/Pi'
import PageTitle from 'piramis-base-components/src/components/PageTitle.vue'

import { Component, Mixins } from 'vue-property-decorator'

@Component({
  'components': {
    CurrentModule,
    NotAvailableOptionsOverlay,
    CenteredColumnLayout,
    PageTitle,
    CreateTriggerModal
  },
  data() {
    return {
      MediaEditorMode
    }
  }
})
export default class ReferralSystem extends Mixins(Placeholders, UseFields, InputSetups, TariffsTagsHelper, TempCurrentModuleMixin) {

  isTriggerSet(key: string): boolean {
    return this.$store.state.chatState.chat.config[key] && !!this.$store.state.chatState.chat.config[key].length
  }

  created() {
    this.setCurrentModule(ModuleTypesEnum.ReferralModule)
  }
}
